.App {
	text-align: center;
}

.App-logo {
	margin-top: 3em;
	height: 20vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-contents {
	flex-flow: column;
	height: 250px;
	margin: 0 0 1em;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: space-around;
}

.App-link {
	color: #61dafb;
}

.App-link-img {
	width: 300px;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.home {
	height: 100%;
	text-align: center;
	background-color: #333;
}

.title {
	margin-top: 0px;
	font-size: 3.8em;
	color: #fff;
}

html {
	height: 100%;
	margin: 0;
}

body {
	height: 100%;
	margin: 0;
	background-color: #333;
	font-family: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif;
}

#root {
	height: 100%;
	margin: 0;
	color: white;
	background-color: #333;
}

.menu-link {
	text-decoration: none;
}

.game-button {
	display: block;
	color: #fff;
	width: 260px;
	font-size: 2.5em;
	border: 1pt solid #fff;
}

.game-button:active {
	background-color: #333;
	opacity: 0.33;
}

/* GamePage */
.game-location-date {
	color: white;
	text-align: left;
	font-size: 1em;
	padding: 0.2em;
}

.score-table {
	width: 100%;
	height: 47%;
	border-spacing: 0;
	border: none;
}
.score-table td,
.score-table th {
	border: none;
	border-top: 1px solid #999;
	border-left: 1px solid #999;
	text-align: center;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.score-table tr:last-child th,
.score-table tr:last-child td {
	border-bottom: 1px solid #999;
}
.score-table th:last-child,
.score-table td:last-child {
	border-right: 1px solid #999;
}
.score-table tr th:last-child,
.score-table tr td:last-child {
	border-right: none;
}
.score-table tr th:first-child,
.score-table tr td:first-child {
	border-left: none;
}
.not-empty {
	padding: 5px;
}
.empty {
	padding: 13px;
}
.count-table {
	width: 100%;
	height: 49%;
	table-layout: fixed;
}
.count-label {
	color: #fff;
	font-size: 3em;
	background-color: #333;
	border-style: none;
	outline: none;
}
.count-ball {
	background-color: #2ecc71;
}
.count-strike {
	background-color: #f1c40f;
}
.count-out {
	background-color: #e74c3c;
}
.error-msg {
	height: 50%;
	text-align: center;
	color: white;
	font-size: 2em;
	padding-top: 2em;
}
.loading {
	font-size: 8px;
	background-color: #2980b9;
}

* {
	box-sizing: border-box;
}
body {
	font-family: 'Avenir', 'Helvetica, Neue', 'Helvetica', 'Arial';
}

/******** モーダルCSS ********/
.modalArea {
	display: block;
	position: fixed;
	z-index: 10; /*サイトによってここの数値は調整 */
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: #333;
}

.modalBg {
	width: 100%;
	height: 100%;
	background-color: rgba(30, 30, 30, 0.9);
}

.modalWrapper {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 70%;
	max-width: 500px;
	padding: 10px 30px;
	background-color: #fff;
}

.closeModal {
	position: absolute;
	top: 0.5rem;
	right: 1rem;
	cursor: pointer;
}

/* テキストの選択はできないように */
.user-select {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/********** GAMES **********/
.game-title {
	font-size: 1.5em;
	width: 100%;
	text-align: center;
	padding: 1em;
}

.list {
	color: #fff;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	-webkit-flex-direction: column;
	flex-direction: column;
	margin: 0;
	padding: 0;
	/* border-top: 1pt solid #d3d3d3; */
	/* IE, Edge 対応 */
	-ms-overflow-style: none;
	/* Firefox 対応 */
	scrollbar-width: none;
}

/* Chrome, Safari 対応 */
.list::-webkit-scrollbar {
	display: none;
}

/* 追加読み込み */
.loading-add-game {
	display: -webkit-flex;
	display: flex;
	align-items: center;
	width: 100%;
	height: 120px;
	text-align: center;
}
/* 追加読み込み テキスト*/
.loading-add-game p {
	text-align: center;
	width: 100%;
}

.list-item {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	-webkit-flex-direction: column;
	flex-direction: column;
	list-style: none;
	width: 100%;
	height: 120px;
	padding: 0.3em;
	border-bottom: 1pt solid #d3d3d3;
}

.location-date {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	height: 20px;
	list-style: none;
	padding: 0;
}
.location-date > .location {
	font-size: small;
}
.location-date > .date {
	font-size: small;
}

.team-score {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	align-items: center;
	list-style: none;
	justify-content: space-around;
	width: 100%;
	height: 80px;
	padding: 0;
	margin-top: 2%;
	margin-bottom: 2%;
}
.team-score > .team {
	flex: 1;
	font-size: larger;
	line-height: 2.5;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.team-score > .score {
	flex: 1;
	font-size: larger;
	line-height: 2.5;
	text-align: center;
}
.team-score ul {
	padding: 0;
}
.team-score ul > li {
	flex: 1;
}
.vs {
	margin-left: 1em;
	margin-right: 1em;
}
.update {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	justify-content: flex-end;
	list-style: none;
	width: 100%;
	height: 20px;
	font-size: small;
}
.score ul {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	list-style: none;
}

.accent {
	color: #2980b9;
}
.infinite-list {
	overflow: auto;
	border-top: 1pt solid #d3d3d3;
}
